import React from "react"
import { CookiesProvider, withCookies } from "react-cookie"
import { Link } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import t from "../locale"

import "../assets/sass/style.scss"
class Layout extends React.Component {
  state = {
    cookieOpen: false,
  }
  componentDidMount() {
    const { cookies } = this.props
    const isAcceptedCoookie = !!cookies.get("cookie-accept-hogra")
    !isAcceptedCoookie && this.setState({ cookieOpen: true })
  }

  acceptCookie = () => {
    const { cookies } = this.props

    const promiseSetCookie = new Promise(resolve =>
      resolve(cookies.set("cookie-accept-hogra", "active", { path: "/" }))
    )
    promiseSetCookie.then(() => {
      this.setState({ cookieOpen: false })
    })
  }

  render() {
    const {
      children,
      lang,
      translationPL,
      translationFO,
      translationEN,
      postTranslations,
      postLanguage,
    } = this.props
    return (
      <>
        <CookiesProvider>
          <Header
            lang={lang}
            translationPL={translationPL}
            translationFO={translationFO}
            translationEN={translationEN}
            postTranslations={postTranslations}
            postLanguage={postLanguage}
          />
          <span id="home"></span>
          <main>{children}</main>
          {
            <div
              className={`cookie-baner ${this.state.cookieOpen ? "open" : ""}`}
              id="mainBelt"
            >
              <div className="container">
                <p className="cookie-baner__text">
                  {lang === "en"
                    ? "Our site uses cookies to personalise content and analyse traffic. For more information read our Cookie Policy."
                    : lang === "fo"
                    ? "Okkara síða brúkar farspor til at persónliggera innihald og at greina vitjanir. Vilt tú vita meira, so les um okkara farspor"
                    : lang === "pl"
                    ? "Aby zoptymalizować naszą stronę internetową i stale ją ulepszać, używamy plików cookie"
                    : ""}
                </p>
                <button
                  className="btn btn--white cookie-baner__button"
                  onClick={this.acceptCookie}
                >
                  {t("ACCEPT", lang)}
                </button>
                <Link
                  className="btn btn--white"
                  to={t("/cookies-policy", lang)}
                >
                  {t("LEARN MORE", lang)}
                </Link>
              </div>
            </div>
          }
          <Footer lang={lang} />
        </CookiesProvider>
      </>
    )
  }
}

export default withCookies(Layout)
