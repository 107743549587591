import tranlations_pl from "./pl"
import tranlations_en from "./en"
import tranlations_fo from "./fo"

const t = (name, lang = "en") => {
  lang = lang.toLowerCase()
  try {
    switch (lang) {
      case "en":
        return tranlations_en[name] || name
      case "pl":
        return tranlations_pl[name] || name
      case "fo":
        return tranlations_fo[name] || name
      default:
        return name
    }
  } catch (error) {
    return name
  }
}
export default t
