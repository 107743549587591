import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { Fade } from "react-awesome-reveal"

import t from "../locale"

const Footer = ({ lang }) => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner inner">
          <Fade triggerOnce direction="left" delay={150}>
            <AnchorLink to="/#home" className="inner__title">
              Hogra
            </AnchorLink>
          </Fade>
          <Fade triggerOnce direction="right" delay={150}>
            <p className="inner__desc">
              {t("CREATIVE ADVERTISING AND GRAPHIC DESIGN", lang)}
            </p>
          </Fade>
          <Fade triggerOnce direction="right" delay={150}>
            <ul className="inner__menu menu">
              <li className="menu__item">
                <Link to={t("/", lang)}>{t("Home", lang)}</Link>
              </li>
              <li className="menu__item">
                <Link to={t("/about-us/", lang)}>{t("About", lang)}</Link>
              </li>
              <li className="menu__item">
                <AnchorLink to={`${t("/", lang)}#portfolio`}>
                  {t("Portfolio", lang)}
                </AnchorLink>
              </li>
              <li className="menu__item">
                <Link to={t("/contact/", lang)}>{t("Contact", lang)}</Link>
              </li>
            </ul>
          </Fade>
          <Fade triggerOnce direction="right" delay={150}>
            <ul className="inner__socials socials">
              <li className="socials__item">
                <a
                  href="https://www.facebook.com/Hogra/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/images/ico-fb.png")}
                    alt="Facebook"
                    className="social__icon"
                  />
                </a>
              </li>
              <li className="socials__item">
                <a
                  href="https://www.linkedin.com/company/hogra/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={require("../assets/images/ico-linkedin.png")}
                    alt="Linkedin"
                    className="social__icon"
                  />
                </a>
              </li>
            </ul>
          </Fade>
        </div>
      </div>
      <div className="footer__copyrights copyrights">
        <p className="copyrights__text">
          &copy; {new Date().getFullYear()}. By{" "}
          <a href="/" target="_blank" rel="noreffer">
            Hogra
          </a>
        </p>
      </div>
    </footer>
  )
}
export default Footer
