const tranlations_en = {
  // Header
  "/": "/en/",
  "/about-us/": "/en/about-us/",
  "About us": "About us",
  Contact: "Contact",
  "/contact/": "/en/contact/",
  About: "About",
  Home: "Home",

  //Buttons
  "See more": "See more",
  "Read more": "Read more",
  ACCEPT: "Accept",
  "LEARN MORE": "Learn more",
  Back: "Back",

  //Projects
  "Prev project": "Prev project",
  "Next project": "Next project",

  //Home
  Campaigns: "Campaigns",
  Video: "Video",
  "Packaging design": "Packaging design",
  Logos: "Logos",
  Websites: "Websites",
  Others: "Others",

  //Contact form
  "Full name*": "Full name*",
  "Message*": "Message*",
  "Sending...": "Sending...",
  Submit: "Submit",
  "Your message has been sent. Thank you.":
    "Your message has been sent. Thank you.",

  "Takk fyri!": "Thank you!!",
  "Vit svara tær so skjótt, vit kunnu.":
    "for contacting us. <br />We will reply as soon as possible.",
  "*I agree to the processing of my personal data by Hogra, Frutroed 14, FO 100 Tórshavn, Faroe Islands, TIN: FO 572810 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "*I agree to the processing of my personal data by Hogra, Frutroed 14, FO 100 Tórshavn, Faroe Islands, TIN: FO 572810 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our",
  "Privacy Policy": "Privacy Policy",
  "/privacy-policy": "/en/privacy-policy",
  "/cookies-policy": "/en/cookies-policy",
}

export default tranlations_en
