const tranlations_fo = {
  // Header
  "/": "/",
  "/about-us/": "/um-okkum/",
  "About us": "Um okkum",
  Contact: "Samband",
  "/contact/": "/samband/",
  About: "Um okkum",
  Home: "Heim",

  //Foter
  "CREATIVE ADVERTISING AND GRAPHIC DESIGN":
    "Kreativ marknaðarføring og grafisk sniðgáva",

  //Buttons
  "See more": "Síggj meira",
  "Read more": "Les meira",
  ACCEPT: "Góðtak",
  "LEARN MORE": "Fá meira at vita",
  Back: "Back",

  //Projects
  "Prev project": " Fyrra arbeiði",
  "Next project": "Næsta arbeiði",
  "If you have any questions, don't hestitate to":
    "Um tú hevur spurningar, set teg í",
  "contact us": "samband við okkum",

  //Home
  Campaigns: "Kampanjur",
  Video: "Video",
  "Packaging design": "Pakkninssniðgáva",
  Logos: "Búmerki",
  Websites: "Heimasíður",
  Others: "Annað",

  //Contact form
  "Full name*": "Fult navn*",
  "Message*": "Boð*",
  "Sending...": "Sendur...",
  Submit: "Send",
  "Your message has been sent. Thank you.": "Boðini eru send - Takk fyri",
  "Takk fyri!": "Takk fyri!",
  "Vit svara tær so skjótt, vit kunnu.": "Vit svara tær so skjótt, vit kunnu.",
  "*I agree to the processing of my personal data by Hogra, Frutroed 14, FO 100 Tórshavn, Faroe Islands, TIN: FO 572810 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "Eg gevi við hesum samtykki til at Hogra viðger mínar persónsupplýsingar samsvarandi og til tey endamál, ið eru lýst í",
  "Privacy Policy": "Dátuverndarkunningini",
  "/privacy-policy": "/datuverndarkunning/",
  "/cookies-policy": "/cookies-policy",
}

export default tranlations_fo
