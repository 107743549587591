const tranlations_pl = {
  // Header
  "/": "/pl/",
  "/about-us/": "/pl/o-nas/",
  "About us": "O nas",
  Contact: "Kontakt",
  "/contact/": "/pl/kontakt/",
  About: "O nas",
  Home: "Strona główna",

  //Buttons
  "See more": "Zobacz więcej",
  "Read more": "Czytaj więcej",
  ACCEPT: "Zaakceptuj",
  "LEARN MORE": "Dowiedz się więcej",
  Back: "Powrót",

  //Projects
  "Prev project": "Poprzedni projekt",
  "Next project": "Następny projekt",
  "If you have any questions, don't hestitate to":
    "Jeśli masz jakiekolwiek pytania",
  "contact us": "skontaktuj się z nami!",

  //Home
  Campaigns: "Kampanie",
  Video: "Filmy",
  "Packaging design": "Projektowanie opakowań",
  Logos: "Logo",
  Websites: "Strony internetowe",
  Others: "Inne",

  //Contact form
  "Full name*": "Imię i nazwisko*",
  "Message*": "Wiadomość*",
  "Sending...": "Wysyłanie...",
  Submit: "Wyślij",
  "Your message has been sent. Thank you.":
    "Twoja wiadomośc została wysłana. Dziękujemy",
  "Takk fyri!": "Dziękujemy!",
  "Vit svara tær so skjótt, vit kunnu.":
    "za skontaktowanie się z nami. <br /> Odpowiemy najszybciej jak to możliwe.",
  "*I agree to the processing of my personal data by Hogra, Frutroed 14, FO 100 Tórshavn, Faroe Islands, TIN: FO 572810 for the purpose of marketing services involving the use of products and services. Information obligation in accordance with art. 13 of the General Regulation on the Protection of Personal Data (GDPR) of 27 April 2016 (Official Journal EU L 119 of 04.05.2016): information about the data processing by us, as well as the rights of each person submitting its data is available in our":
    "Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez firmę Hogra, Plac Wolności 13/2, 35-073 Rzeszów (Administrator danych), w celu marketingu bezpośredniego dotyczącego własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13 ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27 kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016).",
  "Privacy Policy": "Polityka Prywatności",
  "/privacy-policy": "/pl/polityka-prywatnosci/",
  "/cookies-policy": "/pl/polityka-cookies",
}

export default tranlations_pl
